import { FC, useMemo, useState } from "react";

import {
  Badge,
  Box,
  Button,
  ChakraAccordion,
  ChakraAccordionButton,
  ChakraAccordionItem,
  ChakraAccordionPanel,
  CheckIcon,
  ChevronRightIcon,
  CloseIcon,
  Column,
  CopyIcon,
  Dialog,
  Drawer,
  DrawerBody,
  DrawerHeader,
  ExitIcon,
  ExpandIcon,
  Heading,
  IconButton,
  Paragraph,
  Pill,
  Row,
  SectionHeading,
  Tooltip,
  Text,
  ObjectIcon,
  NumberIcon,
  TextStringIcon,
  BooleanIcon,
  ChatIcon,
  ClipboardButton,
  BreakdownIcon,
  InformationIcon,
  DownloadIcon,
} from "@hightouchio/ui";
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "src/router";
import { isPresent } from "ts-extras";
import { useClipboard } from "use-clipboard-copy";
import { Editor } from "src/components/editor";
import { PageSpinner } from "src/components/loading";
import { Private } from "src/components/private";
import {
  SyncAttemptQuery,
  useAttemptedRowsByPrimaryKeyQuery,
  useRequestInfoSetQuery,
  useSanityErrorCodeQuery,
  useSyncAttemptQuery,
  useTransformedSyncRunConfigurationQuery,
} from "src/graphql";
import { newPylonMessage } from "src/lib/pylon";
import { Markdown } from "src/ui/markdown";
import { Placeholder } from "src/ui/table/placeholder";
import { downloadJson, downloadText } from "src/utils/download";
import {
  DEPRECATED_ERROR,
  processRequestInfo,
  RequestInfo,
} from "src/utils/syncs";
import { isSyncMatchBoosted } from "src/pages/syncs/sync/matchbooster";
import JSONBig from "json-bigint";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { syncOpTypeBadge } from "./rows";
import {
  isBoolean,
  isNumber,
  isObject,
  isString,
  min,
  sortBy,
  orderBy as lodashOrderBy,
} from "lodash";
import { Table, useTableConfig } from "src/ui/table";
import { Card } from "src/components/card";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import {
  CodeWithOverflow,
  generateLinkProps,
} from "src/pages/syncs/sync/components/error-origin-info-modal";
import { format } from "date-fns";
import { useUser } from "src/contexts/user-context";
import { ErrorCodeDetails } from "src/types/sync-errors";
import { ElementOf } from "ts-essentials";

type SyncRun = ElementOf<SyncAttemptQuery["sync_attempts"]>["sync_request"];
type Sync = NonNullable<SyncRun>["sync"];

enum SortKeys {
  Key = "key",
  Value = "value",
}

export const RunDebug: FC = () => {
  const {
    run_id: runId,
    sync_id: syncId,
    row_id: rowId,
  } = useParams<{ run_id: string; sync_id: string; row_id: string }>();
  const navigate = useNavigate();

  const { data: run, isLoading: attemptLoading } = useSyncAttemptQuery(
    {
      syncRequestId: runId ?? "",
    },
    {
      select: (data) => data.sync_attempts[0]?.sync_request,
    },
  );

  const primaryKey = run?.sync?.segment?.primary_key;

  // Want to refer to the config of the given run, instead on the sync
  const { data: syncRunConfig } = useTransformedSyncRunConfigurationQuery(
    { id: syncId ?? "", runId: runId ?? "" },
    {
      enabled: Boolean(runId && syncId),
      select: (data) => data.getTransformedSyncRunConfiguration,
    },
  );
  const syncRanWithMatchBooster = syncRunConfig
    ? isSyncMatchBoosted(syncRunConfig)
    : false;

  const { data: selectedRowData, isLoading: selectedRowLoading } =
    useAttemptedRowsByPrimaryKeyQuery(
      {
        alreadyHashed: true,
        destinationInstanceId: Number(syncId),
        id: rowId ?? "",
        onlyRejected: false,
        onlySuccessful: false,
        plannerType: run?.planner_type ?? "", // default to diffing type sync flow
        syncRequestId: Number(runId),
      },
      { enabled: Boolean(rowId) },
    );

  const selectedRow = selectedRowData?.getAttemptedRowsByPrimaryKey?.rows?.[0];

  const { data: sanityError, isLoading: sanityErrorLoading } =
    useSanityErrorCodeQuery(
      { errorCode: selectedRow?.error?.rejectedRowErrorCode },
      {
        select: (data) => data.getErrorCode,
        enabled: Boolean(selectedRow?.error?.rejectedRowErrorCode),
      },
    );

  const requestInfoKeys = useMemo<string[] | undefined>(() => {
    return selectedRow?.requestInfoKeys?.filter(isPresent);
  }, [selectedRow]);

  const { data: requestInfoSetData, isLoading: requestInfoSetLoading } =
    useRequestInfoSetQuery(
      {
        requestInfoKeys: requestInfoKeys ?? [],
        plannerType: run?.planner_type ?? "", // default to diffing type sync flow
      },
      { enabled: Boolean(requestInfoKeys?.length) },
    );

  const definition = run?.sync?.destination?.definition;

  const requestsData = requestInfoSetData?.getRequestInfoSet;
  const requests = useMemo(
    () =>
      requestsData
        ?.map((request) => processRequestInfo(request, definition))
        ?.map((request, index) => ({ ...request, id: index }))
        ?.filter((request) => request.method !== "Contact.bulkload") || [],
    [requestsData],
  );
  const selectedRowValue = JSON.parse(selectedRow?.fields ?? "{}");

  const fields = Object.entries(selectedRowValue).map(([key, value]) => {
    const type = classifyType(value);

    return {
      key,
      type,
      value,
      displayValue: type === "object" ? JSON.stringify(value) : String(value),
    };
  });

  const { onSort, orderBy } = useTableConfig<{
    [key in SortKeys]: "asc" | "desc";
  }>({
    defaultSortKey: undefined,
    sortOptions: Object.values(SortKeys),
  });

  const sortedFields = useMemo(() => {
    // default sort is PK first, then key desc
    if (!orderBy) {
      return sortBy(fields, (field) =>
        field.key === primaryKey ? "" : field.key,
      );
    }

    return lodashOrderBy(
      fields,
      ({ key, value, displayValue }) =>
        orderBy.key
          ? key
          : isEmptyValue(value)
            ? value
            : displayValue.toLowerCase(),
      Object.values(orderBy),
    );
  }, [orderBy, fields]);

  const { search: searchParams } = useLocation();
  const closeDrawer = () => navigate(`..${searchParams}`);
  if (
    requestInfoSetLoading ||
    attemptLoading ||
    selectedRowLoading ||
    sanityErrorLoading
  ) {
    return (
      <Drawer isOpen onClose={closeDrawer} size="2xl">
        <PageSpinner />
      </Drawer>
    );
  }
  if (!run || !primaryKey || !selectedRow || syncRanWithMatchBooster) {
    return <Navigate to={`..${searchParams}`} replace />;
  }

  const earliestRequest = min(
    requests
      .map((request) =>
        request.meta?.invokedTimestamp
          ? new Date(request.meta?.invokedTimestamp).toISOString()
          : null,
      )
      .filter(isPresent),
  );

  return (
    <>
      <Drawer isOpen onClose={closeDrawer} size="2xl">
        <DrawerHeader>
          <Row justifyContent="space-between" width="100%" alignItems="center">
            <Heading>
              <Row gap={2} alignItems="center">
                {selectedRowValue[primaryKey]}
                {syncOpTypeBadge[selectedRow.opType]}
              </Row>
              <Text color="text.secondary">
                {earliestRequest &&
                  format(new Date(earliestRequest), "MMMM d, yyyy h:mm a")}
              </Text>
            </Heading>
            <IconButton
              aria-label="Close"
              icon={CloseIcon}
              onClick={closeDrawer}
            />
          </Row>
        </DrawerHeader>
        <DrawerBody p={0}>
          <Column h="100%" overflow="scroll" gap={6}>
            {selectedRow.rejectionReason &&
              ![DEPRECATED_ERROR, "Error: " + DEPRECATED_ERROR].includes(
                selectedRow.rejectionReason,
              ) && (
                <Box pt={6}>
                  <ErrorDisplay
                    errorInfo={sanityError}
                    errorMessage={selectedRow.rejectionReason}
                    scope={selectedRow.error?.scope ?? "destination"}
                    sync={run.sync}
                  />
                </Box>
              )}
            {requests.length > 0 ? (
              <Column
                gridTemplateColumns="50% 50%"
                display="grid"
                flex={1}
                borderTop="1px solid"
                borderColor="base.border"
              >
                <Column
                  backgroundColor="base.lightBackground"
                  p={6}
                  h="100%"
                  borderRight="1px solid"
                  borderColor="base.border"
                >
                  <Row alignItems="center" gap={2} mb={4}>
                    <SectionHeading>Row data</SectionHeading>
                  </Row>
                  <Table
                    backgroundColor="transparent"
                    width="100%"
                    data={sortedFields}
                    columns={[
                      {
                        name: "Column name",
                        max: "50%",
                        sortDirection: orderBy?.key,
                        onClick: () => onSort(SortKeys.Key),
                        cell: ({ key, type }) => (
                          <Row alignItems="baseline" gap={2} overflow="hidden">
                            <Box
                              fontSize="2xl"
                              display="inline"
                              color="text.secondary"
                              sx={{
                                transform: "translateY(1px)", // The icons and the text are ever so slightly misaligned.
                              }}
                            >
                              {dataTypeToIcon[type]}
                            </Box>
                            <TextWithTooltip
                              size="sm"
                              color="text.secondary"
                              isMonospace
                              sx={{
                                textTransform: "uppercase",
                              }}
                            >
                              {key}
                            </TextWithTooltip>
                            <Text size="sm" isMonospace>
                              {key === primaryKey && "(PK)"}
                            </Text>
                          </Row>
                        ),
                      },
                      {
                        name: "Value",
                        sortDirection: orderBy?.value,
                        onClick: () => onSort(SortKeys.Value),
                        cell: ({ value, displayValue }) => {
                          return (
                            <Row
                              alignItems="center"
                              justifyContent="space-between"
                              w="100%"
                              sx={{
                                ":hover > .clipboard": {
                                  display: "inline-block",
                                },
                              }}
                            >
                              <Text size="sm" isTruncated>
                                {isEmptyValue(value) ? (
                                  <EmptyValue value={value} />
                                ) : (
                                  displayValue
                                )}
                              </Text>

                              <Box className="clipboard" display="none">
                                <ClipboardButton text={displayValue} />
                              </Box>
                            </Row>
                          );
                        },
                      },
                    ]}
                  />
                </Column>
                <Column p={6}>
                  <Row alignItems="center" gap={2} mb={4}>
                    <SectionHeading>Request</SectionHeading>
                    <Pill size="sm">{requests.length.toLocaleString()}</Pill>
                  </Row>

                  <ChakraAccordion allowMultiple>
                    {requests.map((request) => (
                      <Request key={request.id} requestInfo={request} />
                    ))}
                  </ChakraAccordion>
                </Column>
              </Column>
            ) : (
              <Box p={6}>
                <Placeholder
                  content={{
                    title: "No requests were found",
                    body: (
                      <Paragraph>
                        <Markdown>
                          {`Request details aren't stored when the [sync
                  mode](https://hightouch.com/docs/syncs/types-and-modes#sync-modes) is ‘archive’ or ‘all.’ If you need help, please contact our support team.`}
                        </Markdown>
                        <Button
                          mt={6}
                          icon={ChatIcon}
                          size="md"
                          variant="secondary"
                          onClick={() =>
                            newPylonMessage(
                              "I'm experiencing an issue with my sync " +
                                "and could use some assistance. There are no stored requests for the row. " +
                                `Here's a link to the row: <a href="${window.location.href}">${window.location.href}</a>.`,
                            )
                          }
                        >
                          Chat with support
                        </Button>
                      </Paragraph>
                    ),
                  }}
                  error={false}
                />
              </Box>
            )}
          </Column>
        </DrawerBody>
      </Drawer>
    </>
  );
};

const isEmptyValue = (value: unknown): value is "" | undefined | null =>
  value === "" || value === undefined || value === null;

const EmptyValue = ({ value }: { value: "" | null | undefined }) => {
  const label = value === "" || value === undefined ? "empty" : "null";
  return (
    <Box backgroundColor="base.background" px={2} py={1}>
      <Text
        style={{
          fontSize: "var(--chakra-fontSizes-sm)",
          fontFamily: "monospace",
          textTransform: "uppercase",
          color: "var(--chakra-colors-text-secondary)",
        }}
      >
        {label}
      </Text>
    </Box>
  );
};

const RequestBanner = ({
  method,
  destination,
  status,
}: Pick<RequestInfo, "method" | "destination" | "status">) => (
  <Row justifyContent="space-between" w="100%" alignItems="center" gap={2}>
    <Column overflow="hidden">
      <Text style={{ textAlign: "left", fontFamily: "monospace" }}>
        {method}
      </Text>
      <Row
        textAlign="start"
        alignItems="center"
        sx={{
          ":hover > .clipboard": {
            display: "inline-block",
          },
        }}
      >
        <Text color="text.secondary" size="sm" isTruncated>
          {destination}
        </Text>
        <Box
          my={-1}
          className="clipboard"
          display="none"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <ClipboardButton text={destination} size="sm" />
        </Box>
      </Row>
    </Column>
    <Box minW="auto">
      <Badge variant={status.match(/E[Rr][Rr]/) ? "danger" : "success"}>
        {status}
      </Badge>
    </Box>
  </Row>
);

const getUrlIfValid = (destination: string) => {
  try {
    return new URL(destination);
  } catch {
    return undefined;
  }
};

const RequestDetails = ({
  requestBody,
  responseBody,
  destination,
  meta,
  requestIsJson,
  requestIsXml,
  responseIsJson,
  responseIsXml,
}: RequestInfo) => {
  const destinationUrl = getUrlIfValid(destination);

  return (
    <Private>
      <Column gap={4} mt={1}>
        <Row borderLeft="2px solid" borderColor="base.border" pl={2}>
          {destinationUrl && (
            <Row gap={4}>
              <Column>
                <Text size="sm" color="text.secondary">
                  Base URL
                </Text>
                {destinationUrl.pathname && (
                  <Text size="sm" color="text.secondary">
                    Path
                  </Text>
                )}
                {destinationUrl.searchParams.size > 0 && (
                  <Text size="sm" color="text.secondary">
                    Parameters
                  </Text>
                )}
              </Column>
              <Column>
                <Text size="sm">{destinationUrl.host}</Text>
                <Text size="sm">{destinationUrl.pathname}</Text>
                <Text size="sm">{destinationUrl.search.replace("?", "")}</Text>
              </Column>
            </Row>
          )}
        </Row>

        <Data
          body={requestBody}
          destinationName={destination}
          isJSON={requestIsJson}
          isXML={requestIsXml}
          timestamp={meta?.invokedTimestamp}
          title="Request"
        />
        <Data
          body={responseBody}
          destinationName={destination}
          isJSON={responseIsJson}
          isXML={responseIsXml}
          timestamp={meta?.finishedTimestamp}
          title="Response"
        />
      </Column>
    </Private>
  );
};

const Request = ({ requestInfo }: { requestInfo: RequestInfo }) => {
  return (
    <ChakraAccordionItem
      mb={2}
      border="1px solid"
      borderColor="base.border"
      borderRadius="md"
      overflow="hidden"
      outline="none"
    >
      {({ isExpanded }) => {
        return (
          <>
            <ChakraAccordionButton
              cursor="pointer"
              css={{ ":focus": { outline: "none" } }}
              pr={3}
            >
              {/* Chakra accordions collapse the icon if there's not enough space*/}
              <Box w="calc(100% - 40px)">
                <RequestBanner {...requestInfo} />
              </Box>
              <Box
                ml={4}
                fontSize="18px"
                color="text.secondary"
                transform={isExpanded ? "rotate(90deg)" : ""}
                transition="transform 150ms ease-in-out"
                as={ChevronRightIcon}
              />
            </ChakraAccordionButton>

            <ChakraAccordionPanel>
              {isExpanded && <RequestDetails {...requestInfo} />}
            </ChakraAccordionPanel>
          </>
        );
      }}
    </ChakraAccordionItem>
  );
};

const Data: FC<
  Readonly<{
    title: string;
    body?: string;
    timestamp?: string;
    destinationName?: string;
    isJSON?: boolean;
    isXML?: boolean;
  }>
> = ({ title, body, timestamp, isJSON, isXML }) => {
  const clipboard = useClipboard({
    copiedTimeout: 600,
  });
  const [fullscreen, setFullscreen] = useState<boolean>(false);

  // Treat any request/response body over ~1.2 MB as too large to view in the window.
  // A lot of payloads are around 1 MB, so add a little buffer on top of that.
  const isFileTooLarge = (body?.length ?? 0) > 1024 * 1024 * 1.2;
  const largeFileMessage = `${title} body is too large to display. Download to view the content.`;

  const copyBody = () => {
    clipboard.copy(body);
  };

  const downloadBody = () => {
    const fileName = `${title}-${timestamp}`;
    isJSON
      ? downloadJson(JSONBig.parse(body ?? ""), `${fileName}.json`)
      : downloadText(body ?? "", `${fileName}.txt`);
  };

  return (
    <Column gap={1}>
      <Row
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Row sx={{ alignItems: "center", mr: 2 }}>
          <Text size="sm">{title}</Text>
          {timestamp && (
            <Text size="sm" color="text.secondary" ml={1}>
              ({timestamp})
            </Text>
          )}
        </Row>

        {body && (
          <Row>
            <IconButton
              aria-label="Download request body."
              variant="tertiary"
              onClick={downloadBody}
              icon={DownloadIcon}
              size="sm"
            />
            <Tooltip message={largeFileMessage} isDisabled={!isFileTooLarge}>
              <IconButton
                isDisabled={isFileTooLarge}
                aria-label="Copy to clipboard."
                color={clipboard.copied ? "success.base" : "gray.600"}
                icon={clipboard.copied ? CheckIcon : CopyIcon}
                onClick={copyBody}
                size="sm"
              />
            </Tooltip>

            {fullscreen ? (
              <IconButton
                aria-label="Exit full screen."
                icon={ExitIcon}
                onClick={() => setFullscreen(false)}
                size="sm"
              />
            ) : (
              <Tooltip message={largeFileMessage} isDisabled={!isFileTooLarge}>
                <IconButton
                  isDisabled={isFileTooLarge}
                  aria-label="Enter full screen."
                  icon={ExpandIcon}
                  onClick={() => setFullscreen(true)}
                  size="sm"
                />
              </Tooltip>
            )}
          </Row>
        )}
      </Row>
      <Column
        flex={1}
        maxH="280px"
        border="1px solid"
        borderColor="base.border"
        borderRadius={6}
        overflow="hidden"
      >
        {body && !isFileTooLarge ? (
          <Editor
            bg="white"
            readOnly
            value={body?.toString() ?? ""}
            language={isJSON ? "json" : isXML ? "xml" : undefined}
          />
        ) : (
          <Box
            textAlign="center"
            fontSize="sm"
            color="text.secondary"
            minH="100px"
            alignContent="center"
          >
            {isFileTooLarge ? largeFileMessage : "No payload to show"}
          </Box>
        )}
      </Column>

      <Dialog
        isOpen={fullscreen}
        variant="info"
        {...({ width: "3xl" } as any)} // Bit weird, but the dialog _can_ tolerate an extra wide width, it's just not preferred
        title={title}
        actions={
          <>
            <Button onClick={copyBody}>
              {clipboard?.copied ? "Copied" : "Copy"}
            </Button>
            <Button onClick={() => setFullscreen(false)}>Close</Button>
          </>
        }
        onClose={() => setFullscreen(false)}
      >
        <Column
          width="100%"
          height="100%"
          border="1px"
          borderColor="base.border"
          borderRadius="md"
          overflow="hidden"
        >
          <Editor
            bg="white"
            readOnly
            value={body?.toString() ?? ""}
            language={isJSON ? "json" : isXML ? "xml" : undefined}
          />
        </Column>
      </Dialog>
    </Column>
  );
};

const ErrorDisplay = ({
  errorInfo,
  errorMessage,
  scope,
  sync,
}: {
  errorInfo: ErrorCodeDetails | null;
  errorMessage: string;
  scope: "source" | "destination";
  sync: Sync;
}) => {
  const { isEmbedded } = useUser();

  const destination = sync?.destination;
  const model = sync?.segment;
  const source = model?.connection;

  const orderedTypes = [
    "internal",
    "external",
    "sync",
    "model",
    "source",
    "destination",
  ];
  // We disregard the order of links in Sanity, and instead use the order defined in orderedTypes
  const orderedLinks = errorInfo?.links?.sort((a, b) => {
    const aIndex = orderedTypes.indexOf(a.type);
    const bIndex = orderedTypes.indexOf(b.type);
    return aIndex - bIndex;
  });

  const definition =
    scope === "source" ? source?.definition : destination?.definition;

  const fallbackName = scope === "source" ? "source" : "destination";

  return (
    <Card p={4} mx={6} gap={4}>
      <Row justifyContent="space-between">
        <Row gap={2} alignItems="center">
          <IntegrationIcon
            name={definition?.name ?? fallbackName}
            src={definition?.icon}
          />
          <Text fontWeight="medium">
            Error message from {definition?.name ?? fallbackName}
          </Text>
        </Row>
        <ClipboardButton text={errorMessage} />
      </Row>

      <CodeWithOverflow lineClampCount="5" maxLines={15} isError>
        <Text isMonospace color="danger.base">
          {errorMessage}
        </Text>
      </CodeWithOverflow>

      {errorInfo?.userFriendlyMessage && (
        <Column>
          <Text fontWeight="medium">Troubleshooting</Text>
          <Text>
            <Markdown useParagraphMargins>
              {errorInfo?.userFriendlyMessage}
            </Markdown>
          </Text>
          {orderedLinks && !isEmbedded && (
            <Row gap={2} flexWrap="wrap">
              {orderedLinks?.map((link, idx) => {
                const props = generateLinkProps(
                  link,
                  origin,
                  destination,
                  model,
                  source,
                  sync,
                );
                return props ? (
                  <Row key={idx}>
                    <Link
                      href={props.url ?? "#"}
                      isExternal={link?.type === "external"}
                    >
                      <Button icon={props.icon} size="md" variant="secondary">
                        {props.label}
                      </Button>
                    </Link>
                  </Row>
                ) : null;
              })}
            </Row>
          )}
        </Column>
      )}

      {!isEmbedded && (
        <Column gap={2}>
          <Text fontWeight="medium">Need more help?</Text>
          <Text>
            If you feel stuck, please reach out! We want to make sure you have
            all the help you need. Our team is available to help you
            troubleshoot this error.
          </Text>
          <Row gap={2} mt={2} flexWrap="wrap">
            <Button
              icon={ChatIcon}
              size="md"
              variant="secondary"
              onClick={() =>
                newPylonMessage(
                  "I'm experiencing an issue with my sync " +
                    "and could use some assistance. The error message I'm receiving is: " +
                    `'${errorMessage}'. Here's a link to the row: <a href="${window.location.href}">${window.location.href}</a>.`,
                )
              }
            >
              Chat with support
            </Button>
            {definition?.docs && (
              <Link
                href={`${import.meta.env.VITE_DOCS_URL}/${definition?.docs}`}
                isExternal
              >
                <Button icon={InformationIcon} size="md" variant="secondary">
                  Read docs for {definition.name ?? fallbackName}
                </Button>
              </Link>
            )}
          </Row>
        </Column>
      )}
    </Card>
  );
};

const classifyType = (value: unknown) => {
  if (isString(value)) return "string";
  if (isNumber(value)) return "number";
  if (isBoolean(value)) return "boolean";
  if (isObject(value)) return "object";
  if (isEmptyValue(value)) return "empty";
  return "unknown";
};

const dataTypeToIcon: { [k in ReturnType<typeof classifyType>]: JSX.Element } =
  {
    string: <TextStringIcon />,
    number: <NumberIcon />,
    empty: <BreakdownIcon />,
    unknown: <BreakdownIcon />,
    boolean: <BooleanIcon />,
    object: <ObjectIcon />,
  };
