import { DecisionEngineChannelType } from "@hightouch/lib/customer-data/decision-engine/types";
import {
  Box,
  Column,
  PlusIcon,
  Row,
  SectionHeading,
  StatusBadge,
  Text,
  Tooltip,
} from "@hightouchio/ui";
import { FC } from "react";
import pluralize from "pluralize";

import { LinkButton, useNavigate, useOutletContext } from "src/router";
import { Card } from "src/components/card";
import { DeleteButton } from "src/components/delete-button";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import {
  DecisionEngineFlowQuery,
  useDeleteDecisionEngineMessageMutation,
} from "src/graphql";
import { OutletContext } from "src/pages/decision-engines";
import { ChannelIcon } from "src/pages/decision-engines/components/channel-icon";
import { getChannelDefinition } from "src/pages/decision-engines/definitions";
import { Table } from "src/ui/table";
import { DestinationResourceLink } from "./message/components/campaign-link";

export type Message = NonNullable<
  DecisionEngineFlowQuery["decision_engine_flows_by_pk"]
>["messages"][0];

type Props = {
  messages: Array<Message>;
};

export const Messages: FC<Readonly<Props>> = ({ messages }) => {
  const { engine } = useOutletContext<OutletContext>();
  const navigate = useNavigate();
  const deleteMutation = useDeleteDecisionEngineMessageMutation();
  const isSingleDestination = engine.channels.length === 1;
  const channelDefinition = isSingleDestination
    ? getChannelDefinition(engine.channels[0]!.destination.type)
    : null;

  return (
    <Card gap={4} p={6}>
      <Row justify="space-between" gap={4}>
        <Column>
          <SectionHeading>Messages</SectionHeading>
          <Text>
            Individual communications that can be sent as a part of this flow
          </Text>
        </Column>
        <Tooltip
          message="Configure a channel before adding messages"
          isDisabled={Boolean(engine.channels.length)}
        >
          <LinkButton
            href="messages/add"
            icon={PlusIcon}
            isDisabled={!engine.channels.length}
          >
            Add message
          </LinkButton>
        </Tooltip>
      </Row>
      <Table
        columns={[
          {
            name: "Status",
            max: "max-content",
            cell: ({ segment_id, enabled }) => {
              if (!segment_id) {
                return <StatusBadge variant="draft">Draft</StatusBadge>;
              }
              if (!enabled) {
                return <StatusBadge variant="inactive">Disabled</StatusBadge>;
              }
              return <StatusBadge variant="success">Enabled</StatusBadge>;
            },
          },
          {
            name: "Name",
            cell: ({ message: { name, channel } }) => (
              <Row gap={2} align="center" overflow="hidden">
                <ChannelIcon type={channel.type as DecisionEngineChannelType} />
                <TextWithTooltip fontWeight="medium">{name}</TextWithTooltip>
              </Row>
            ),
          },
          {
            // Use specific resource name if there is only one destination
            name: channelDefinition
              ? pluralize(
                  channelDefinition.getResourceName(engine.channels[0]!.config),
                )
              : "Destination resource",
            cell: ({ message: { channel }, config }) => {
              if (channel.type === "raw") {
                return (
                  <Row gap={2} align="center">
                    <IntegrationIcon
                      src={channel.destination.definition.icon}
                      name={channel.destination.definition.name}
                    />
                    <Text fontWeight="medium">{config.baseMessageId}</Text>
                  </Row>
                );
              }

              return (
                <DestinationResourceLink
                  channel={channel}
                  resourceId={config.resourceId ?? config.campaignId}
                />
              );
            },
          },
          {
            name: "",
            max: "max-content",
            cell: ({ message: { id } }) => {
              return (
                <Box onClick={(event) => event.stopPropagation()}>
                  <DeleteButton
                    variant="icon"
                    label="message"
                    onDelete={async () => {
                      const response = await deleteMutation.mutateAsync({
                        id,
                      });
                      if (
                        response.deleteDecisionEngineMessage.__typename ===
                        "DecisionEngineError"
                      ) {
                        throw new Error(
                          response.deleteDecisionEngineMessage.error,
                        );
                      }
                    }}
                  />
                </Box>
              );
            },
          },
        ]}
        placeholder={{
          title: "No messages",
        }}
        onRowClick={({ message: { id } }) => navigate(`messages/${id}`)}
        data={messages}
      />
    </Card>
  );
};
