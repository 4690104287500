import type { DecisionEngineChannelConfig } from "@hightouch/lib/customer-data/decision-engine/types";

export const generateDecisionEngineSyncConfig = ({
  destinationType,
  channelConfig,
  campaignId,
  isPreview,
}: {
  destinationType: "iterable" | "sfmc" | "s3" | "braze" | "klaviyo";
  channelConfig: DecisionEngineChannelConfig;
  campaignId: string;
  isPreview: boolean;
}) => {
  switch (destinationType) {
    case "iterable": {
      const config: any = {
        type: "trigger",
        campaignId: {
          type: "standard",
          from: "campaign_id",
        },
        mappings: [hightouchMapping],
      };
      // Always use email-based sending when previewing emails
      if (channelConfig.recipientType === "userId" && !isPreview) {
        config.userId = {
          type: "standard",
          from: "external_id",
        };
      } else {
        config.email = {
          type: "standard",
          from: "external_id",
        };
      }
      return config;
    }
    case "braze": {
      let additionalConfiguration = {};
      if (isPreview) {
        // In preview mode, we insert a new user based on the interaction ID
        // and set the email via the attribute mapping
        additionalConfiguration = {
          sendToExistingOnly: false,
          attributeMappings: [
            {
              type: "standard",
              from: "external_id",
              to: "email",
            },
          ],
          userMapping: {
            type: "standard",
            from: "id",
            to: "external_user_id",
          },
        };
      } else {
        // In execution mode, we only send to existing users
        // via the external ID
        additionalConfiguration = {
          sendToExistingOnly: true,
          userMapping: {
            type: "standard",
            from: "external_id",
            to: "external_user_id",
          },
        };
      }
      return {
        type: "trigger",
        campaignId,
        mappings: [hightouchMapping],
        ...additionalConfiguration,
      };
    }
    case "sfmc":
      return {
        type: "trigger",
        campaignId: {
          type: "standard",
          from: "campaign_id",
        },
        email: {
          type: "standard",
          from: "external_id",
        },
        mappings: [hightouchMapping],
      };
    case "s3":
      return {
        skipEmpty: true,
        timestampOffsetSeconds: 0,
        mode: "insert",
        fileType: "json",
        mappings: [
          hightouchMapping,
          {
            type: "standard",
            from: "campaign_id",
            to: "message_id",
          },
        ],
        objectKey: channelConfig.objectKey,
        configVersion: 0,
      };
    case "klaviyo": {
      return {
        type: "event",
        eventName: {
          type: "standard",
          from: "campaign_id",
        },
        externalIdMapping: {
          from: "external_id",
          to: "email",
        },
        // We want to pass everything under the event properties (customMappings) and not modify the user profile (mappings)
        mappings: [],
        customMappings: [hightouchMapping],
      };
    }
  }
};

const hightouchMapping = {
  type: "template",
  template: `{% assign parsedRecs = row['hightouch_recommendation'] | parse %}{%assign parsedUser = row['hightouch_user'] | parse %}{{ | json_construct: 'user', parsedUser, 'recommendation', parsedRecs }}`,
  to: "hightouch",
} as const;
