import {
  PerUserAggregationType,
  AttributionTimeWindow,
  ColumnType,
  AudienceAggregationType,
  AssetType,
  InteractionType,
} from "src/types/visual";

export const headerStyles = {
  p: "0 !important",
  borderBottom: "1px solid",
};

export const cellStyles = {
  py: "0 !important",
  px: "0 !important",
};

export const TimeWindowLabels: Record<AttributionTimeWindow, string> = {
  [AttributionTimeWindow.SevenDays]: "7 days",
  [AttributionTimeWindow.ThirtyDays]: "30 days",
  [AttributionTimeWindow.SixtyDays]: "60 days",
  [AttributionTimeWindow.NinetyDays]: "90 days",
};

export const TimeWindowOptions = Object.entries(TimeWindowLabels).map(
  ([key, value]) => ({
    label: value,
    value: key,
  }),
);

export const CampaignResultsLimit = 30;

export const CampaignFiltersAllowMultiValue = false;
export const CampaignFiltersAllowPercentile = false;

export const NumberColumns = [ColumnType.Number, ColumnType.BigInt];

export const AllowedAggregationTypePairs: Map<
  PerUserAggregationType,
  AudienceAggregationType[]
> = new Map([
  [PerUserAggregationType.Count, [AudienceAggregationType.Sum]], // total count
  [PerUserAggregationType.Sum, [AudienceAggregationType.Sum]], // sum of property
]);

export const Delimiter = "==";

export const InteractionTypeToAssetType: Record<InteractionType, AssetType> = {
  [InteractionType.EmailClicked]: AssetType.Email,
  [InteractionType.EmailDelivered]: AssetType.Email,
  [InteractionType.EmailOpened]: AssetType.Email,
  [InteractionType.Unsubscribe]: AssetType.Email,

  [InteractionType.Sessions]: AssetType.Ad,

  [InteractionType.SmsSent]: AssetType.Sms,
  [InteractionType.SmsDelivered]: AssetType.Sms,
  [InteractionType.SmsClicked]: AssetType.Sms,
  [InteractionType.SmsReplied]: AssetType.Sms,
  [InteractionType.SmsOptedOut]: AssetType.Sms,
};
