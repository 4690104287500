import type { EventCondition, PropertyCondition } from "./condition";
import type {
  FormulaTraitConfig,
  TraitCondition,
  TraitConfig,
  TraitType,
} from "./trait-definitions";
import * as yup from "yup";

export enum ColumnType {
  String = "string",
  Number = "number",
  BigInt = "bigint",
  Boolean = "boolean",
  Timestamp = "timestamp",
  Date = "date",
  Json = "json",
  JsonArrayStrings = "json-array-strings",
  JsonArrayNumbers = "json-array-numbers",
  Null = "null",
  Unknown = "unknown",
}

export type Column = RawColumn | RelatedColumn | TransformedColumn;

export type RelatedColumn = {
  type: "related";
  path: string[];
  column: RawColumn | TraitColumn | EventTraitColumn | InlineAggregatedTrait;
};

export type RawColumn = {
  type: "raw";
  modelId: string;
  name: string;
};

export type TransformedColumn = {
  type: "transformed";
  column: TraitColumn | InlineFormulaTrait;
};

export function getColumnValidator() {
  return yup.object({
    type: yup
      .string()
      .equals(["raw", "related"], "must be a raw or related column")
      .required(),
    modelId: yup.string().when("type", {
      is: "raw",
      then: yup.string().required(),
      otherwise: yup.string().optional(),
    }),
    name: yup.string().when("type", {
      is: "raw",
      then: yup.string().required(),
      otherwise: yup.string().optional(),
    }),
    path: yup.array().when("type", {
      is: "related",
      then: yup.array().required(),
      otherwise: yup.array().optional(),
    }),
    column: yup.object().when("type", {
      is: "related",
      then: yup.object().required(),
      otherwise: yup.object().optional(),
    }),
  });
}

export type TraitColumn = {
  type: "trait";
  traitDefinitionId: string;
  conditions:
    | PropertyCondition[] // Legacy format that is still supported by the backend
    | TraitCondition[]; // The frontend will convert it to this type and send it to the backend

  // Only used for syncing
  // 1) Mapper component uses the name as a label in the UI
  // 2) Visual column resolver uses this to compute a unique, friendly name
  name?: string;
};

// This is essentially a `TraitColumn` that hasn't been materialized in the DB so it doesn't have a `traitDefinitionId`
export type InlineTraitColumn = InlineAggregatedTrait | InlineFormulaTrait;

export type InlineAggregatedTrait = {
  type: "inline_trait";
  traitType: Exclude<TraitType, TraitType.Formula>;
  traitConfig: Exclude<TraitConfig, FormulaTraitConfig>;
  conditions: TraitCondition[];
  relationshipId: string;
};

export type InlineFormulaTrait = {
  type: "inline_trait";
  traitType: TraitType.Formula;
  traitConfig: FormulaTraitConfig;
};

export type EventTraitColumn = {
  type: "event_trait";
  filteredEvent: Omit<EventCondition, "operator" | "value">;
  traitType: TraitType;
  traitConfig: TraitConfig;
};

export interface MergedColumn extends RelatedColumn {
  column: RawColumn;
}

export type ColumnReference =
  | RawColumn
  | RelatedColumn
  | TransformedColumn
  | TraitColumn
  | InlineTraitColumn
  | EventTraitColumn;

export const isColumnReference = (
  property: unknown,
): property is ColumnReference => {
  return typeof property === "object";
};

export const isRelatedColumn = (
  property: string | ColumnReference | null,
): property is RelatedColumn => {
  return (
    Boolean(property) &&
    typeof property === "object" &&
    property?.type === "related"
  );
};

export const isTransformedColumn = (
  property: string | ColumnReference | null,
): property is TransformedColumn => {
  return (
    Boolean(property) &&
    typeof property === "object" &&
    property?.type === "transformed"
  );
};

export const isTraitColumn = (
  column: ColumnReference | null,
): column is TraitColumn => column?.type === "trait";

export const isMergedColumn = (
  column: ColumnReference | string,
): column is MergedColumn =>
  isRelatedColumn(column) &&
  !isTraitColumn(column.column) &&
  !isInlineTraitColumn(column.column);

export const isInlineTraitColumn = (
  column: ColumnReference | string | null,
): column is InlineTraitColumn => {
  return (
    Boolean(column) &&
    typeof column === "object" &&
    column?.type === "inline_trait"
  );
};
