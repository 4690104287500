import {
  DECISION_ENGINE_SUPPORTED__DESTINATIONS,
  DecisionEngineChannelConfig,
  DecisionEngineSupportedDestination,
} from "@hightouch/lib/customer-data/decision-engine/types";
import { DecisionEngineDestinationResource } from "src/graphql";

type ChannelDefinition = {
  campaignSetup: (
    channelConfig: DecisionEngineChannelConfig,
  ) => "freeform" | "template" | "event-triggered";
  getChannelConfig: (
    channelConfig: DecisionEngineChannelConfig,
  ) => DecisionEngineChannelConfig;
  getResourceUrl: (
    campaign: {
      id: string;
      name: string;
      status: string | null;
    },
    destinationConfig: Record<string, unknown>,
  ) => string;
  isInactive: (resource: DecisionEngineDestinationResource) => boolean;
  isPreviewSupported: (channelConfig: DecisionEngineChannelConfig) => boolean;
  getReferenceString: (reference: string) => string;
  getResourceName: (channelConfig: DecisionEngineChannelConfig) => string;
};

export const CHANNEL_DEFINITION: {
  [key in DecisionEngineSupportedDestination]: ChannelDefinition;
} = {
  s3: {
    campaignSetup: () => "freeform",
    getChannelConfig: (config) => config,
    getResourceUrl: () => "",
    isInactive: () => false,
    isPreviewSupported: () => false,
    getReferenceString: (s) => s,
    getResourceName: () => "bucket",
  },
  iterable: {
    campaignSetup: () => "template",
    getChannelConfig: (config) => ({
      ...config,
      recipientType: config?.recipientType ?? "email",
    }),
    getResourceUrl: (campaign) =>
      `https://app.iterable.com/campaigns/${campaign.id}`,
    isInactive: (campaign) => campaign.status === "Ready",
    isPreviewSupported: () => true,
    getReferenceString: (s) => s,
    getResourceName: () => "campaign",
  },
  sfmc: {
    campaignSetup: (channelConfig) =>
      channelConfig.type === "dataExtensions" ? "freeform" : "template",
    getChannelConfig: (config) => ({
      ...config,
      type: config?.type ?? "trigger",
    }),
    getResourceUrl: () => "",
    isInactive: () => false,
    isPreviewSupported: (channelConfig) =>
      channelConfig.type !== "dataExtensions",
    getReferenceString: (s) => s,
    getResourceName: (channelConfig) =>
      channelConfig.type === "dataExtensions" ? "automation" : "template",
  },
  braze: {
    campaignSetup: () => "template",
    getChannelConfig: (config) => config,
    getResourceUrl: (campaign, destinationConfig) => {
      // https://www.braze.com/docs/api/basics/#endpoints
      const restRegionToDashboard = {
        "iad-01.braze.com": "dashboard-01.braze.com",
        "iad-02.braze.com": "dashboard-02.braze.com",
        "iad-03.braze.com": "dashboard-03.braze.com",
        "iad-04.braze.com": "dashboard-04.braze.com",
        "iad-05.braze.com": "dashboard-05.braze.com",
        "iad-06.braze.com": "dashboard-06.braze.com",
        "iad-07.braze.com": "dashboard-07.braze.com",
        "iad-08.braze.com": "dashboard-08.braze.com",
        "fra-01.braze.eu": "dashboard-01.braze.eu",
        "fra-02.braze.eu": "dashboard-02.braze.eu",
      };
      return `https://${
        restRegionToDashboard[destinationConfig.region as string]
      }/engagement/campaigns?start=0&limit=10&globalFilter="${encodeURIComponent(
        campaign.name,
      )}"&columnFilters%5Bstatus%5D=all&sortby=last_edited&sortdir=-1`;
    },
    // Braze list API does not allow us to return the status of a campaign
    // We need to add a new resolver to get the status of a campaign
    isInactive: () => false,
    isPreviewSupported: () => true,
    getReferenceString: (s) => "api_trigger_properties.${" + s + "}",
    getResourceName: () => "campaign",
  },
  klaviyo: {
    campaignSetup: () => "event-triggered",
    getChannelConfig: (config) => config,
    getResourceUrl: (flow) => `https://www.klaviyo.com/flow/${flow.id}/edit`,
    isInactive: (flow) => flow.status !== "live",
    isPreviewSupported: () => false,
    getReferenceString: (s) => `event.${s}`,
    getResourceName: () => "flow",
  },
};

export const getChannelDefinition = (
  destinationType: string,
): ChannelDefinition => {
  if (
    DECISION_ENGINE_SUPPORTED__DESTINATIONS.includes(
      destinationType as DecisionEngineSupportedDestination,
    )
  ) {
    return CHANNEL_DEFINITION[destinationType];
  }

  return {
    campaignSetup: () => "freeform",
    getChannelConfig: (config) => config,
    getResourceUrl: () => "",
    isInactive: () => false,
    isPreviewSupported: () => false,
    getReferenceString: (s) => s,
    getResourceName: () => "resource",
  };
};
